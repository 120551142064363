var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-body"
  }, [_vm.preference ? _c('div', {
    staticClass: "row"
  }, [_vm.trust ? _c('div', {
    staticClass: "col-md-12 form-group"
  }, [_c('label', {
    attrs: {
      "for": "Jenis Harta"
    }
  }, [_vm._v(_vm._s(_vm.$t("property-type2")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.property.property_type))])]) : _vm._e()]) : _vm._e(), _vm.property.property_type == 'Bank' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "bank_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("bank-type")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bank_type,
      expression: "form.bank_type"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "bank_type",
      "placeholder": "Contoh: CIMB, MBB, BIMB",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.bank_type
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bank_type", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "bank_branch"
    }
  }, [_vm._v(_vm._s(_vm.$t("bank-branches")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bank_branch,
      expression: "form.bank_branch"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "bank_branch",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.bank_branch
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bank_branch", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "bank_account_no"
    }
  }, [_vm._v(_vm._s(_vm.$t("accmembership-no")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bank_account_no,
      expression: "form.bank_account_no"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "bank_account_no",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.bank_account_no
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bank_account_no", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.property.property_type == 'Kenderaan' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("vehicle-type")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Contoh : motosikal, kereta, van, lori, basikal dan lain-lain",
      "height": "15",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.transport_type,
      expression: "form.transport_type"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.transport_type
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "transport_type", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("made-in")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Contoh : Proton, Produa, Honda, Toyota, Yamaha dan lain-lain",
      "height": "15",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.transport_manufacturer,
      expression: "form.transport_manufacturer"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.transport_manufacturer
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "transport_manufacturer", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("made-in")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Contoh : Viva 850, CR-V 2.0L I-VTEC (A), KRISS AN110F dan lain-lain",
      "height": "15",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.transport_model,
      expression: "form.transport_model"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.transport_model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "transport_model", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("org.reg-no")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Contoh : BNF 2705",
      "height": "15",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.transport_registration_number,
      expression: "form.transport_registration_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.form.transport_registration_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "transport_registration_number", $event.target.value);
      }
    }
  })])]) : _vm._e(), _vm.property.property_type == 'Saham' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_vm.preference && ['1', '2'].includes(_vm.trust.hibah_type_id) ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("stock-name")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.preference.hibah_fixed_saham_type,
      expression: "preference.hibah_fixed_saham_type"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "hibah_fixed_saham_type",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.preference.hibah_fixed_saham_type
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.preference, "hibah_fixed_saham_type", $event.target.value);
      }
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("org-name")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.institution_name,
      expression: "moveableProperty.institution_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": "Nama Organisasi*",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.moveableProperty.institution_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "institution_name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_vm.trust && ['1', '2'].includes(_vm.trust.hibah_type_id) && _vm.preferenceLabel ? _c('label', [_vm._v(" " + _vm._s(_vm.preferenceLabel) + " ")]) : _vm._e(), !['1', '2'].includes(_vm.trust.hibah_type_id) ? _c('label', [_vm._v(" " + _vm._s(_vm.$t("accmembership-no")) + " ")]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.moveableProperty.account_number,
      expression: "moveableProperty.account_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": "No Akaun / No Ahli*",
      "readonly": ![0, 1, 2].includes(_vm.trust.status) || !_vm.amendmentId
    },
    domProps: {
      "value": _vm.moveableProperty.account_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.moveableProperty, "account_number", $event.target.value);
      }
    }
  })])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }